import React from 'react';
import { Button, Modal, Descriptions, Layout, Row, Col } from 'antd';
import Text from './typography/Text';
import MetaHelmet from './MetaHelmet';
import Link from './router/Link';
import constants from '../constants';
import styles from './Footer.module.scss';
const AntFooter = Layout.Footer;

function Footer({ light = false }) {
  function info() {
    const modal = Modal.info({
      maskClosable: true,
      width: 450,
      className: styles.contactModal,
      icon: null,
      title: 'Contact',
      content: (
        <Descriptions bordered style={{paddingTop: 20}}>
          <Descriptions.Item label="Contact Email" span={3}>
            <a href={`mailto:${constants.main.CONTACT_EMAIL}`}>{constants.main.CONTACT_EMAIL}</a>
          </Descriptions.Item>
          <Descriptions.Item label="Support Email" span={3}>
            <a href={`mailto:${constants.main.SUPPORT_EMAIL}`}>{constants.main.SUPPORT_EMAIL}</a>
          </Descriptions.Item>
          <Descriptions.Item label="Sales Email" span={3}>
            <a href={`mailto:${constants.main.SALES_EMAIL}`}>{constants.main.SALES_EMAIL}</a>
          </Descriptions.Item>
          <Descriptions.Item label="Schedule a meeting" span={3}>
            <Button onClick={() => {
              modal.destroy();
              window.Calendly.initPopupWidget({url: constants.main.DEMO_CALENDLY});
            }}>
              Product Demo
            </Button>
            <br/>
            <br/>
            <Button onClick={() => {
              modal.destroy();
              window.Calendly.initPopupWidget({url: constants.main.ENTERPRISE_SALES_CALENDLY});
            }}>
              Enterprise Sales
            </Button>
          </Descriptions.Item>
        </Descriptions>
      ),
      closable: true,
      okType: 'secondary',
      okText: 'Close',
      onOk() {},
    });
  }

  return (
    <AntFooter style={{ textAlign: 'right' }} className={light ? styles.light : ''}>
      <MetaHelmet>
        <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
        <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript"></script>
      </MetaHelmet>
      <Row type="flex" justify="space-between" align="bottom">
        <Col className={styles.footerMenu}>
          <Link to="/pricing#faq"><Text trans>FAQ</Text></Link>&nbsp;
          <span>·&nbsp;</span>
          <Button type="link" style={{padding: 0}} onClick={info}><Text trans>Contact</Text></Button>&nbsp;
          <span className={styles.menuSeparator}>·&nbsp;</span>
          <Link to="/terms"><Text trans>Terms</Text></Link>&nbsp;
          <span>·&nbsp;</span>
          <Link to="/privacy"><Text trans>Privacy</Text></Link>&nbsp;&nbsp;
          <span className={styles.menuSeparator}>|&nbsp;&nbsp;</span>
          Copyright © {(new Date()).getFullYear().toString()} {constants.main.LEGAL_ENTITY}
        </Col>
        <Col style={{textAlign: 'right'}}>
          <Text trans>Created by</Text>&nbsp;
          <a href="https://ajayp.app" title="Ajay Patel">
            Ajay Patel
          </a>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
