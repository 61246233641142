import React from 'react';
import styles from './BrowserMockup.module.scss';

function BrowserMockup({type="", barSize="inherit", children, style}) {
  return (
    <div className={`${styles['browser-mockup']} ${styles["with-"+type] || ''}`} style={Object.assign({}, style, {fontSize: barSize})}>
      {children}
    </div>
  );
}

export default BrowserMockup;