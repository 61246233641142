import _ from 'lodash';
import React from 'react';
import { Trans } from '../../i18n';
import { Typography } from 'antd';
const AntParagraph = Typography.Paragraph;

function Paragraph(props) {
  // Clean up props for the child tag
  const cProps = _.clone(props);
  delete cProps.trans;
  delete cProps.children;

  return (
    <AntParagraph {...cProps}>
      {props.trans 
        ? <Trans>{props.children}</Trans>
        : props.children
      }
    </AntParagraph>
  );
}

export default Paragraph;
