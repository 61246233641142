import React from 'react';
import Image from './Image';
import styles from './Logo.module.scss';
import logoBlack from './logos/logo-black.svg';
import logoWhite from './logos/logo-white.svg';

function Logo({dark, style, className}) {
  return (
    <div style={style} className={className}>
      <div className={styles.logo}>
        <Image src={dark ? logoWhite : logoBlack} height="50px" nointeract />
      </div>
    </div>
  );
}

export default Logo;
