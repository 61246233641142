import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next, useTranslation as _useTranslation, Trans } from "react-i18next";

// Import translations
import { default as en } from "./translations/en.json";
// import { default as de } from "./translations/de.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Initialize with the translations
    lng: "en",
    resources: {
      en: {
        translations: en
      },
      // de: {
      //   translations: de
      // }
    },
    fallbackLng: "en",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    debug: process.env.NODE_ENV !== 'production',
  });

const useTranslation = () => {
  const { t } = _useTranslation();
  return t;
};
const changeLanguage = i18n.changeLanguage.bind(i18n);
export {
  i18n,
  Trans,
  useTranslation,
  changeLanguage,
  _useTranslation,
};
