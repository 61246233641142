import _ from 'lodash';
import React from 'react';
import { Trans } from '../../i18n';
import { Typography } from 'antd';
const AntText = Typography.Text;

function Text(props) {
  // Clean up props for the child tag
  const cProps = _.clone(props);
  delete cProps.trans;
  delete cProps.children;

  return (
    <AntText {...cProps}>
      {props.trans 
        ? <Trans>{props.children}</Trans>
        : props.children
      }
    </AntText>
  );
}

export default Text;
