import React from 'react';
import { Empty, Row, Col }  from 'antd';

function NullState({description, children}) {
  return (
    <Row type="flex" justify="center" align="middle">
      <Col span={24} style={{textAlign: 'center'}}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description} />
        { children }
      </Col>
    </Row>
  );
}

export default NullState;
