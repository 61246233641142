import _ from 'lodash';
import React from 'react';
import { Trans } from '../../i18n';
import { Typography } from 'antd';
const AntTitle = Typography.Title;

function Title(props) {
  // Clean up props for the child tag
  const cProps = _.clone(props);
  delete cProps.trans;
  delete cProps.children;

  return (
    <AntTitle {...cProps}>
      {props.trans 
        ? <Trans>{props.children}</Trans>
        : props.children
      }
    </AntTitle>
  );
}

export default Title;
