import _ from 'lodash';

import React, { useState } from 'react';
import { Blurhash } from "react-blurhash";

function Image(props) {
  const [loaded, setLoaded] = useState(false);

  // Handle nointeract prop
  const wrapperStyles = {display: 'inline'};
  const blurhashWrapperStyles = {};
  const styles = Object.assign({}, props.style || {});
  if (props.nointeract) {
    styles.userSelect = 'none';
    styles.pointerEvents = 'none';
  }
  if (!loaded) {
    styles.opacity = .01;
    styles.width = 1;
    styles.height = 1;
    wrapperStyles.marginRight = '-1px';
  }
  if (props.fit) {
    styles.objectFit = props.fit;
  }
  if (props.width) {
    blurhashWrapperStyles.width = props.width;
  }
  if (props.height) {
    blurhashWrapperStyles.height = props.height;
  }

  // Decode BlurHash
  let blurhashDecoded = undefined;
  let blurhashWidth = 0;
  let blurhashHeight = 0;
  if (props.blurhash) {
    blurhashDecoded = props.blurhash.split('<')[0];
    const wh = props.blurhash.split('<')[1].slice(0, -1).split(",");
    blurhashWidth = parseInt(wh[0]);
    blurhashHeight = parseInt(wh[1]);
    if (props.width) {
      blurhashWidth = props.width;
    }
    if (props.height) {
      blurhashHeight = props.height;
    }
  }

  // Clean up props for the DOM img tag
  const imgProps = _.clone(props);
  delete imgProps.nointeract;
  delete imgProps.fit;
  delete imgProps.blurhash;

  return (
    <div style={wrapperStyles}>
      { (!loaded && props.blurhash) && 
        <div style={blurhashWrapperStyles}>
          <Blurhash
            hash={blurhashDecoded}
            width={blurhashWidth}
            height={blurhashHeight}
            resolutionX={32}
            resolutionY={32}
            punch={1}
            className={props.className}
            style={props.style}
          />
        </div>
      }
      <img {...imgProps} alt={props.alt} style={styles} onLoad={() => setLoaded(true)} />
    </div>
  );
}

export default Image;
