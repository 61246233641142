import _ from 'lodash';
import React from 'react';
import { Trans } from '../../i18n';
import { useRouteMatch } from "react-router-dom";
import { HashLink as RouterLink } from 'react-router-hash-link';
import resolveRelativeRoute from './resolve-relative-route';

const Link = React.forwardRef(function (props, ref) {
  const match = useRouteMatch();

  // Modify Props
  const mProps = _.clone(props);
  if (mProps.to) {
    mProps.to = resolveRelativeRoute(match, mProps.to);
  }

  // Clean up props for the child tag
  const cProps = _.clone(mProps);
  delete cProps.trans;
  delete cProps.children;

  return (
    <RouterLink {...cProps}>
      {mProps.trans 
        ? <span ref={ref}><Trans>{mProps.children}</Trans></span>
        : <span ref={ref}>{mProps.children}</span>
      }
    </RouterLink>
  );
});

export default Link;