import _ from 'lodash';
import React from 'react';
import { Switch as RouterSwitch, Route as RouterRoute, Redirect as RouterRedirect, useRouteMatch } from "react-router-dom";
import Route from './Route';
import Redirect from './Route';
import resolveRelativeRoute from './resolve-relative-route';

function Switch(props) {
  let chldrn = props.children;
  if (props.children && !_.isArray(props.children)) {
    chldrn = [props.children];
  }

  // Modify Props
  const match = useRouteMatch();
  function modifyProps(props) {
    const mProps = _.clone(props);
    if (mProps.path) {
      mProps.path = resolveRelativeRoute(match, mProps.path);
    } else if (mProps.to) {
      mProps.to = resolveRelativeRoute(match, mProps.to);
    }
    return mProps;
  }

  return (
    <RouterSwitch {...props}>
      {
        chldrn && chldrn.map(function(child, i) {
          if (child.type === Route) {
            return <RouterRoute {...modifyProps(child.props)} key={i}/>
          } else if (child.type === Redirect) {
            return <RouterRedirect {...modifyProps(child.props)} key={i}/>
          } else {
            return child;
          }
        })
      }
    </RouterSwitch>
  );
}

export default Switch;