import React from 'react';
import { Helmet } from "react-helmet";
import constants from '../constants';

function MetaHelmet(props) {
  const appTitle = `${props.title} · ${constants.main.APP_NAME}`;
  return (
    <div>
      { props.title &&
        <Helmet>
          <title>{appTitle}</title>
          <meta name="title" content={appTitle} />
          <meta property="og:title" content={appTitle} />
          <meta property="twitter:title" content={appTitle} />
        </Helmet>
      }
      { props.fullTitle &&
        <Helmet>
          <title>{props.fullTitle}</title>
          <meta name="title" content={props.fullTitle} />
          <meta property="og:title" content={props.fullTitle} />
          <meta property="twitter:title" content={props.fullTitle} />
        </Helmet>
      }
      { props.description &&
        <Helmet>
          <meta name="description" content={props.description} />
          <meta property="og:description" content={props.description} />
          <meta property="twitter:description" content={props.description} />
        </Helmet>
      }
      <Helmet>
        {props.children}
      </Helmet>
    </div>
  );
}

export default MetaHelmet;
