import _ from 'lodash';
import React from 'react';
import { Trans } from '../../i18n';
import { Route as RouterRoute, useRouteMatch } from "react-router-dom";
import resolveRelativeRoute from './resolve-relative-route';

function Route(props) {
  const match = useRouteMatch();

  // Modify Props
  const mProps = _.clone(props);
  mProps.path = resolveRelativeRoute(match, mProps.path);
  if (mProps.path) {
    mProps.path = resolveRelativeRoute(match, mProps.path);
  }

  // Clean up props for the child tag
  const cProps = _.clone(mProps);
  delete cProps.trans;
  delete cProps.children;

  return (
    <RouterRoute {...cProps}>
      {mProps.trans 
        ? <Trans>{mProps.children}</Trans>
        : mProps.children
      }
    </RouterRoute>
  );
}

export default Route;