import React from 'react';
import { Spin, Row, Col }  from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

function Loading({fontSize = 24, children}) {
  const antIcon = <LoadingOutlined style={{ fontSize: fontSize }} spin />;

  return (
    <Row type="flex" justify="center" align="middle">
      <Col span={24} style={{textAlign: 'center'}}>
        <Spin indicator={antIcon} />
        { children }
      </Col>
    </Row>
  );
}

export default Loading;
