export default function resolveRelativeRoute(currentMatch = {path: '/'}, relativeURL) {
  relativeURL = relativeURL.trim();
  if (relativeURL.slice(0,1) !== '.') {
    return relativeURL;
  }
  const currentPath = currentMatch.path;
  let currentPathURL = `root/${currentPath}`;
  if (currentPathURL.slice(-1) !== '/') {
    currentPathURL += '/';
  }
  currentPathURL = currentPathURL.replace(/\/+/g, '/');
  const resolvedURL = (new URL(relativeURL, 'http://'+currentPathURL)).href;
  return resolvedURL.replace('http://root', '');
}