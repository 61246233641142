import fit from 'fit.js';
import React, {useRef, useEffect} from 'react';
import styles from './ResponsiveScalingBox.module.scss';

function ResponsiveScalingBox({ widthPercent = 100, maxWidth = 400, overflow = 'visible', style, children }) {
  const childRef = useRef(null);
  const childParentRef = useRef(null);
  useEffect(() => {
    if (childRef && childParentRef) {
      const watching = fit(childRef.current, childParentRef.current, {cover: true, watch: true});
      return function() {
        watching.off();
      };
    }
  }, [childParentRef, childRef]);

  return (
    <div style={Object.assign({margin: '0 auto', width: `${widthPercent}%`, maxWidth: maxWidth, overflow: overflow}, style)} className={styles['square-box']}>
        <div className={styles['square-content']}>
            <div style={{width: '100%', height: '100%', position: 'relative'}}  ref={childParentRef}>
              <div style={{width: maxWidth, height: maxWidth}} ref={childRef}>
                <div style={{width: '100%', height: '100%', maxWidth: maxWidth, maxHeight: maxWidth, position: 'relative'}}>
                  {children}
                </div>
              </div>
            </div>
        </div>
    </div>
  );
}

export default ResponsiveScalingBox;