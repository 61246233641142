import _ from 'lodash';
import React from 'react';
import { Trans } from '../../i18n';
import { Redirect as RouterRedirect, useRouteMatch } from "react-router-dom";
import resolveRelativeRoute from './resolve-relative-route';

function Redirect(props) {
  const match = useRouteMatch();

  // Modify Props
  const mProps = _.clone(props);
  mProps.to = resolveRelativeRoute(match, mProps.to);
  if (mProps.to) {
    mProps.to = resolveRelativeRoute(match, mProps.to);
  }

  // Clean up props for the child tag
  const cProps = _.clone(mProps);
  delete cProps.trans;
  delete cProps.children;

  return (
    <RouterRedirect {...cProps}>
      {mProps.trans 
        ? <Trans>{mProps.children}</Trans>
        : mProps.children
      }
    </RouterRedirect>
  );
}

export default Redirect;