import _ from 'lodash';
import React from 'react';
import { useTranslation } from '../i18n';
import { Button as AntButton } from 'antd';

function Button(props) {
  const t = useTranslation();

  // Clean up props for the child tag
  const cProps = _.clone(props);
  delete cProps.trans;
  delete cProps.children;

  return (
    <AntButton {...cProps}>
      {props.trans 
        ? t(props.children)
        : props.children
      }
    </AntButton>
  );
}

export default Button;