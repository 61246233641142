// TODO remove the following line later...
/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Router, ScrollToTop, Switch, Route } from "./common";
import constants from './constants';
const Home = lazy(() => import('./marketing/Home'));
const App = lazy(() => import('./app/App'));

function Root() {
  const title = `${constants.main.APP_NAME} · ${constants.main.APP_SHORT_DESCRIPTION}`;
  const url = `${constants.main.BASE_URL}/`;
  const [currentAnchorSearchTime, setCurrentAnchorSearchTime] = useState(100);

  let googleTag = '';
  if (process.env.NODE_ENV === 'production') {
    googleTag = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${constants.main.GOOGLE_ANALYTICS_ID}');
    `;
  }

  // Scroll to a #anchor once the element appears
  useEffect(() => {
    if (window.location.hash.length > 0) {
      setTimeout(function () {
        if (!_.isNil(document.querySelector(window.location.hash))) {
          window.location.href = window.location.hash;
        } else {
          setCurrentAnchorSearchTime(currentAnchorSearchTime + 100);
        }
      }, currentAnchorSearchTime);
    }
  }, [currentAnchorSearchTime]);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />

        <meta name="title" content={title} />
        <meta name="description" content={constants.main.APP_DESCRIPTION} />
        <meta name="keywords" content={constants.main.APP_KEYWORDS} /> 

        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={constants.main.APP_DESCRIPTION} />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/assets/manifest/social-banner/banner.png`} />
        <meta property="og:locale" content="en_US" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={constants.main.APP_DESCRIPTION} />
        <meta property="twitter:image" content={`${process.env.PUBLIC_URL}/assets/manifest/social-banner/banner.png`} />
        <meta name="twitter:site" content={constants.main.TWITTER_ACCOUNT} />

        <link rel="manifest" href="/manifest.json" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="application-name" content={constants.main.APP_NAME} />
        <meta name="apple-mobile-web-app-title" content={constants.main.APP_NAME} />
        <meta name="theme-color" content={constants.main.APP_THEME_COLOR} />
        <meta name="msapplication-navbutton-color" content={constants.main.APP_THEME_COLOR} />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="msapplication-starturl" content={constants.main.APP_PWA_START_ROUTE} />
        <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no" />

        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/assets/manifest/apple-touch-icons/apple-touch-icon.png`} />
        <link rel="apple-touch-icon" sizes="57x57" href={`${process.env.PUBLIC_URL}/assets/manifest/apple-touch-icons/apple-touch-icon-57x57.png`} />
        <link rel="apple-touch-icon" sizes="72x72" href={`${process.env.PUBLIC_URL}/assets/manifest/apple-touch-icons/apple-touch-icon-72x72.png`} />
        <link rel="apple-touch-icon" sizes="76x76" href={`${process.env.PUBLIC_URL}/assets/manifest/apple-touch-icons/apple-touch-icon-76x76.png`} />
        <link rel="apple-touch-icon" sizes="114x114" href={`${process.env.PUBLIC_URL}/assets/manifest/apple-touch-icons/apple-touch-icon-114x114.png`} />
        <link rel="apple-touch-icon" sizes="120x120" href={`${process.env.PUBLIC_URL}/assets/manifest/apple-touch-icons/apple-touch-icon-120x120.png`} />
        <link rel="apple-touch-icon" sizes="144x144" href={`${process.env.PUBLIC_URL}/assets/manifest/apple-touch-icons/apple-touch-icon-144x144.png`} />
        <link rel="apple-touch-icon" sizes="152x152" href={`${process.env.PUBLIC_URL}/assets/manifest/apple-touch-icons/apple-touch-icon-152x152.png`} />
        <link rel="apple-touch-icon" sizes="180x180" href={`${process.env.PUBLIC_URL}/assets/manifest/apple-touch-icons/apple-touch-icon-180x180.png`} />
      
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${constants.main.GOOGLE_ANALYTICS_ID}`}></script>
        <script>
         {googleTag}
        </script>

        <link rel="stylesheet" href={`https://use.typekit.net/${constants.main.TYPEKIT_PROJECT_ID}.css`} />
      </Helmet>
      <div>&nbsp;Page not found.</div>
{/*      <Router>
        <Switch>
          <Route path="./app">
            <ScrollToTop />
            <Suspense fallback={<div></div>}>
              <App />
            </Suspense>
          </Route>
          <Route path="./">
            <ScrollToTop />
            <Suspense fallback={<div></div>}>
              <Home />
            </Suspense>
          </Route>
        </Switch>
      </Router>*/}
    </div>
  );
}

export default Root;
