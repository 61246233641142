import React from 'react';
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { MenuOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from "../i18n";
import { Dropdown, Menu, Divider, Layout, Row, Col } from 'antd';
import Logo from './Logo';
import Avatar from './Avatar';
import { Text } from './typography';
import { Link } from './router';
import Button from './Button';
import constants from '../constants'
import styles from './Header.module.scss';
const AntHeader = Layout.Header;
const { SubMenu } = Menu;

function AccountMenu({home, menuItems = []}) {
  const t = useTranslation();
  const auth = useSelector(state => state.firebase.auth);
  const isLoggedIn = (isLoaded(auth) && !isEmpty(auth));
  const accountInfo = (
    isLoggedIn
    ? <div>
        <Row type="flex" justify="center" align="middle" gutter={25}>
          <Col>
            <Avatar user={auth} />
          </Col>
          <Col style={{lineHeight: '2em'}}>
            <Text style={{color: 'black'}}>{auth.displayName}</Text><br/>
            <Text type="secondary">No organization selected</Text>
          </Col>
        </Row>
      </div>
    : ''
  );
  return (
    <div>
      <Menu placement="bottomRight" className={styles.accountMenu} selectable={false}>
        {
          isLoggedIn && [
            <Menu.Item key={0} style={{pointerEvents: 'none', height: 60}}>
              {accountInfo}
            </Menu.Item>,
            <Menu.Divider key={1} />,
            <Menu.Item key={2}>
              <Link to="/app/account" trans>
                Account
              </Link>
            </Menu.Item>,
            <SubMenu title={t("Organizations")} style={{display: 'flex', flexDirection: 'column-reverse'}} key={3}>
              <Menu.Item disabled>
                No organizations found.
              </Menu.Item>
            </SubMenu>,
            <Menu.Item key={4}>
              <Link to="/app/logout" trans>
                Sign Out
              </Link>
            </Menu.Item>,
          ]
        }
        {isLoggedIn && home && <Menu.Item>
            <Link to="/app/" trans>
              Go to App
            </Link>
          </Menu.Item>
        }
        {isLoggedIn && !home && <Menu.Item>
            <Link to="/">
              {constants.main.APP_NAME} <Trans>Home</Trans>
            </Link>
          </Menu.Item>
        }
        {!isLoggedIn && <Menu.Item>
            <Link to="/app/login" trans>
              Sign in
            </Link>
          </Menu.Item>
        }
        {!isLoggedIn && <Menu.Item>
            <Link to="/app/signup" trans>
              Sign up
            </Link>
          </Menu.Item>
        }
        {(menuItems.length > 0) && <Menu.Divider /> }
        {
          menuItems.map((item, i) => (
            <Menu.Item key={i}>
              <Link to={item.route} trans>
                {item.label}
              </Link>
            </Menu.Item>
          ))
        }
      </Menu>
    </div>
  );
}

function Header({ homeRoute, loggedOutRoute, signupLabel, menuItems=[], home = false, children }) {
  const auth = useSelector(state => state.firebase.auth);
  homeRoute = (!isLoaded(auth) || isEmpty(auth)) ? (loggedOutRoute || homeRoute) : homeRoute;
  const avatar = (
    <Link to="/app/account">
      <Avatar user={auth} />
    </Link>
  );
  const accountSignInSignUp = (home, small = false) => {
    if (small && home) {
      return (
        <Link to="/app/login">
          <Button type="primary" size="default" trans>
            Sign in
          </Button>
        </Link>
      );  
    } else if (small) {
      return (
        <Link to="/">
          <Button type="primary" size="default" trans>
            Go home
          </Button>
        </Link>
      );  
    } else {
      return (
        [
          <Link to="/app/login" trans key={0}>Sign in</Link>,
          <Link to="/app/signup"  key={1}>
            <Button type="primary" size="default" trans>
              {signupLabel}
            </Button>
          </Link>
        ]
      ); 
    }
  };

  return (
    <AntHeader className={home ? styles.light : styles.dark}>
      <Row type="flex" justify="space-between" align="bottom">
        <Col>
          <Link to={homeRoute}>
            <Logo dark={!home} />
          </Link>
        </Col>
        {
          isLoaded(auth) &&
          <Col className={styles.menu}>
            <Row type="flex" justify="center" align="middle">
              <Col xs={{span:0}} sm={{span:0}} md={{span:0}} lg={{span:24}} style={{textAlign: 'right'}}>
                {children}
                {(React.Children.count(children) > 0) && <Divider type="vertical" />}
                {home && !isEmpty(auth) && (
                  <Link to="/app/dashboard">
                    <Button type="primary" size="default">Open dashboard</Button>
                  </Link>
                )}
                {
                  (isLoaded(auth) && !isEmpty(auth))
                  ? <Dropdown overlay={(<AccountMenu home={home} />)}>
                      {avatar}
                    </Dropdown>
                  : accountSignInSignUp(home, false)
                }
              </Col>
              <Dropdown overlay={(<AccountMenu home={home} menuItems={menuItems} />)}>
                <Col xs={{span:24}} sm={{span:24}} md={{span:24}}  lg={{span:0}} className={styles.accountMenuCol}>
                  {
                    (isLoaded(auth) && !isEmpty(auth))
                    ? avatar
                    : <MenuOutlined style={{fontSize: 16}}/>
                  }
                </Col>
              </Dropdown>
            </Row>
          </Col>
        }
      </Row>
    </AntHeader>
  );
}

export default Header;
